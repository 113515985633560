import { Avatar, Divider, Grid, Typography } from "@mui/material";
import hotel from "../../../../assets/img/hotel.jpg";

function DialogReservaHotelInfo() {
	return (
		<Grid spacing={4} container mb={3}>
			<Grid item md={3} xs={12}>
				<Avatar
					src={hotel}
					variant="square"
					sx={{
						fontSize: "90px",
						width: "auto",
						height: "auto",
					}}
				/>
			</Grid>
			<Grid item md={9} xs={12}>
				<Typography variant="h6" mb={2} fontSize={35}>
					Insomnia Hotel
				</Typography>
				<Divider sx={{ border: 1 }} />
				<Typography mb={2} mt={2}>
					El único Hotel del mundo en el que cada habitación es una escape Room.
				</Typography>
				<Typography mb={2}>Cada habitación de nuestro hotel está tematizada de manera diferente a las otras y su escape room es único.</Typography>

				<Typography mb={2}>
					Hora Check-in: 18:00 h / Hora Check-out: 11:30 h
					<br />
				</Typography>
				<Typography mb={2}>
					<strong>Importante:</strong> Las noches sin “El secreto de los Krugger” son sin terror. Solo habitación temática con escape room del
					check-in.
				</Typography>
				<Typography mb={2}>
					"El secreto de los Krugger" solo los viernes (hora de inicio 18:00h). Incluye escape room de la habitación + cena espectáculo con
					experiéncia gastronómica + escape room "El secreto de los Krugger" + alojamiento en habitación temática.
				</Typography>
				<Typography mb={2}>* Disponible en inglés con aviso previo</Typography>
			</Grid>
		</Grid>
	);
}

export default DialogReservaHotelInfo;
